import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import 'vuetify/styles' // Global CSS has to be imported
import { createApp } from 'vue/dist/vue.esm-bundler';
import { createVuetify } from 'vuetify'
import App from './App.vue'
import router from './router'
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";
import '@mdi/font/css/materialdesignicons.css'
import LoadingOverlay from "../src/components/LoadingOverlay.vue"
import LoginForm from "../src/components/LoginForm.vue"
import LoadVoucher from "../src/components/LoadVoucher.vue";

import Login from "../src/components/Login.vue"
import RegisterForm from "../src/components/RegisterForm.vue"
import LoginSignupCombined from "../src/components/LoginSignupCombined.vue"
import Bundles from "../src/components/Bundles.vue"
import Snackbar from "./components/Snackbar.vue"
import TicketStatus from "./components/TicketStatus.vue"
import Support from "./components/Support.vue"
import HomeOverview from "./components/HomeOverview.vue"

const app = createApp(App)

app.component('LoadingOverlay', LoadingOverlay);
app.component('LoginForm', LoginForm);
app.component("LoadVoucher", LoadVoucher);
app.component('Login', Login);
app.component('LoginSignupCombined', LoginSignupCombined);
app.component('RegisterForm', RegisterForm)
app.component('Bundles', Bundles)
app.component('Snackbar', Snackbar)
app.component('TicketStatus', TicketStatus)
app.component('Support', Support)
app.component('HomeOverview', HomeOverview)

app.config.errorHandler = (err, instance, info) => {
  console.error('Vue Global Error Handler:', err, instance, info);
  // Optionally, log errors to an external service
};

const vuetify = createVuetify({

  components,
  directives,
  icons: {
    defaultSet: 'mdi',
  },
  theme: {
    defaultTheme: 'light',
    themes: {
      light: {
        colors: {
          primary: '#3f51b5',
          secondary: '#D32F2F',
          accent: '#8c9eff',
          error: '#b71c1c',
          darkGrey: '#24292e',
          pacificBlue: '#0693e3',
          darkRed: '#92140c',
          white: '#ffffff',
          gray: '#808080',
          mintGreen: '#99ff99',
          limeGreen: '#4CAF50',
          blackPanther: '#424242',
          gray64: '#A3A3A3',
          darkBlue: '#155d86',
          tmwGreen: '#00A652',
          tmwRed: '#CE0701',
          tmwOrange: '#fdb813',
          tmwBlue: '#00acdc',

        }
      },
    },
  },
})

Sentry.init({
  app,
  dsn: "https://dda91dc8b7aa47819c483caa9eab56c8@o106966.ingest.sentry.io/6322630",
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ["localhost", "127.0.0.1:5173", "portal-staging.toomuchwifi.co.za", /^\//],
    }),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});


app.use(router)
app.use(vuetify)

app.mount('#app')

// function getCookie(cname) {
//   var name = cname + "=";
//   var decodedCookie = decodeURIComponent(document.cookie);
//   var ca = decodedCookie.split(';');
//   for (var i = 0; i < ca.length; i++) {
//       var c = ca[i];
//       while (c.charAt(0) === ' ') {
//           c = c.substring(1);
//       }
//       if (c.indexOf(name) === 0) {
//           return c.substring(name.length, c.length);
//       }
//   }
//   return "";
// }

// function setCookie(cname, cvalue) {
//   var expiryDate = new Date();
//   expiryDate.setDate(expiryDate.getDate() + 30);
//   if (cvalue === null || cvalue === "") {
//       cvalue = "direct"
//   }
//   document.cookie = `${cname}=${cvalue}; path=/; expires=${expiryDate.toUTCString()}`
// }

// const queryString = window.location.search;
// const urlParams = new URLSearchParams(queryString);
// const ref = urlParams.get('ref')

// if (!getCookie("referrer")) {
//   setCookie("referrer", ref);
// } else {
//   const current_referrer =  getCookie("referrer")
//   if (current_referrer !== ref && ref !== null) {
//       setCookie("referrer", ref);
//   }
// }

// var referrer_domain = "direct";

// // if (document.referrer){
// // referrer_domain = document.referrer.split('/')[2];
// // }

// if (document.referrer) {

//   if (window.location.Hotspot.referrer.includes("/hotspot/")){
//     console.log("Detected /hotspot/ route");
//     referrer_domain = "HOTSPOT";
//   }
//   else {
//     referrer_domain = document.referrer.split('/')[2];
//   }
// }
// else {
//   referrer_domain = "direct";
// }

// if (!getCookie("referrer_domain")) {
//   setCookie("referrer_domain", referrer_domain);
// }

// console.log("Referrer", document.referrer)
// console.log("Cookie", decodeURIComponent(document.cookie))
var hotspot_referrer = ""
document.addEventListener("DOMContentLoaded", function () {

  async function getMikUsername() {
    req = await axios.get(url = "http://connectzone.toomuchwifi.co.za/node.html", verify = False)

    hotspot_referrer = req.data.username

  }
  function getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i].trim();
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  function setCookie(cname, cvalue, days = 30) {
    var expiryDate = new Date();
    expiryDate.setDate(expiryDate.getDate() + days);
    if (!cvalue) {
      cvalue = "direct";
    }
    const hostname = window.location.hostname;

    //cookies are not shared between domains, also they are domain strict
    //also making it easier for the future generation to test
    if (hostname === "localhost" || hostname === "127.0.0.1") {
      document.cookie = `${cname}=${cvalue}; path=/; expires=${expiryDate.toUTCString()}`;
    } else {
      document.cookie = `${cname}=${cvalue}; path=/; domain=.toomuchwifi.co.za; expires=${expiryDate.toUTCString()}`;
    }
  }

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const ref = urlParams.get('ref') || "direct";

  if (!getCookie("referrer") && hotspot_referrer) {
    setCookie("referrer", hotspot_referrer);
  } else if (getCookie("referrer") !== ref && ref !== "direct") {
    setCookie("referrer", ref);
  }

  var referrer_domain = "direct";

  if (document.referrer) {
    if (window.location.pathname.includes("/hotspot/")) {
      console.log("Detected /hotspot/ route");
      referrer_domain = "HOTSPOT";
    } else if (document.referrer && new URL(document.referrer).searchParams.get('tab') === 'hotspot') {
      console.log("Detected referrer with tab=hotspot");
      referrer_domain = "HOTSPOT";
    } else if (document.referrer) {
      referrer_domain = new URL(document.referrer).hostname;
    }
  }

  console.log("Referrer", getCookie("referrer"));
  if (!getCookie("referrer_domain")) {
    setCookie("referrer_domain", referrer_domain);
  }

});

